import{useState} from "react";

export function Counter(){

const [counter1,setCounter]=useState(1)


    const add1=()=>{
        if(counter1<13){
        setCounter(counter1+1)
        }
}
    
 
    const sub1=()=>{
        if(counter1>0){
        setCounter(counter1-1)
        }
    }

    const [counter2,setCounter2]=useState(1)
    const add2=()=>{
        if(counter2<10){
        setCounter2(counter2+1)
        }
    }
 
    const sub2=()=>{
        if(counter2>0){
            setCounter2(counter2-1)
            }
    }


    const [counter3,setCounter3]=useState(1)
    const add3=()=>{
        if(counter3<44){
        setCounter3(counter3+1)
        }
    }
 
    const sub3=()=>{
        if(counter3>0){
            setCounter3(counter3-1)
            }
    }


    const [counter4,setCounter4]=useState(1)
    const add4=()=>{
        if(counter4<77){
        setCounter4(counter4+1)
    }
}
 
    const sub4=()=>{
        if(counter4>0){
            setCounter4(counter4-1)
            }
    }
    const [total=counter2+counter3,setTotal]=useState(0)
   
    const totals=()=>{
        setTotal(total)
    }
    
    return(
        <>
        <div className="items">
        <div>
        <span>Books:</span>
        <button className="addBook" onClick={add1}>
            +
        </button>
        <button className="remBook" onClick={sub1}>
            -
        </button>
        <span className="totalBooks">{counter1}</span>
        </div>
        </div>
  


        <div className="items">
    <span>Pens:</span>
    <button className="addPen" onClick={add2}>
        +
    </button>
    <button className="remPen" onClick={sub2}>
        -
    </button>
    <span className="totalPens">{counter2}</span>
    </div>
    


    <div className="items">
    <span>Notebook:</span>
    <button className="addNotebook" onClick={add3}>
        +
    </button>
    <button className="remNotebook" onClick={sub3}>
        -
    </button>
    <span className="totalNotebooks">{counter3}</span>
</div>

<div className="items">
<span>Inkpen:</span>
    <button className="addInkpen" onClick={add4}>
        +
    </button>
    <button className="remInkpen" onClick={sub4}>
        -
    </button>
    <span className="totalInkpens">{counter4}</span>
    </div>
    

    <h3>total:{totals}</h3>
    </>

    )
}